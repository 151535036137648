<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps<{
  successTitle?: string
  successDescription?: string
  type: 'success' | 'error' | null
}>()

const isOpened = defineModel<boolean>('isOpened', {
  default: false,
  type: Boolean as PropType<boolean>,
})

const { t } = useI18n()

const resultSuccessDescription = computed(() => {
  if (props.successDescription) {
    return props.successDescription
  }

  return useOpeningHours() ? t('issue.heroSection.leadDialog.success.callNow') : t('issue.heroSection.leadDialog.success.callNextWorkingDay')
})
</script>

<template>
  <LazyLayoutModalSuccess
    v-if="type === 'success'"
    v-model:is-opened="isOpened"
    data-cy="success-modal"
    :description="resultSuccessDescription"
    :title="successTitle"
  />

  <LazyLayoutModalError
    v-if="type === 'error'"
    v-model:is-opened="isOpened"
    :description="$t('contact.issuerDialog.error.description', {
      email: 'info@srovnavacdluhopisu.cz',
    })"
    :title="$t('contact.issuerDialog.error.title')"
  />
</template>
